// This is all you.
require("./bootstrap");

import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);

var swiper1 = new Swiper(".s1", {
    cssMode: true,
    // loop: true,
    centeredSlides: true,

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    keyboard: true,
});

var swiper2 = new Swiper(".s2", {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        // when window width is >= 480px
        300: {
            slidesPerView: 2,
            spaceBetween: 30,
        },

        600: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        // when window width is >= 640px
        920: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
    },
});
